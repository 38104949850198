import Api from '@/services/Index';

const get = async (programId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/simulation/' + programId);
}

const update = async (programId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/simulation/' + programId, formData);
}

export default {
    get,
    update
}
